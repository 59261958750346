let modLogin = {

    Init : () => {

        if( window.localStorage.getItem( 'VDASH_theme' ) != 'null' ) {

            let theme = window.localStorage.getItem( 'VDASH_theme' );

            $('body').attr('class', theme );

            return;
        }
    }
}
module.exports = modLogin;