let modUserUpdate = {

    Init : () => {
        modUserUpdate.Action();
    },
    Action : () => {

        $d.off('submit', '#user_edit_update_form').on('submit', '#user_edit_update_form', function( e ){

            e.preventDefault();

            let userId = $('input[name="edit_user_id"]').val();
            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                if( $('#user_edit_update_form').length ) {

                    $('.modal').hide();
                    $('#app_loader').show();

                    if( $('input[name="edit_user_password"]').val() != $('input[name="edit_user_password_confirm"]').val()) {

                        $('#app_loader').fadeOut( 200 );
        
                        $('.form_notice_error').text( 'Les mots de passe ne correspondent pas !' ).fadeIn( 200 );
        
                        setTimeout( function(){ $('.form_notice').fadeOut(200); }, 2000);
        
                        return;
                    }

                    let data = {
                        user_id : filterXSS(userId),
                        user_email : filterXSS($('input[name="edit_user_email"]').val()),
                        user_role : filterXSS($('select[name="edit_user_role"]').val()),
                        user_password : filterXSS($('input[name="edit_user_password"]').val()),
                        user_password_confirm : filterXSS($('input[name="edit_user_password_confirm"]').val()),
                        is_active : filterXSS($('input[name="edit_user_active"]').prop('checked'))
                    }

                    modUserUpdate.AjaxUpdate( data );
                }
            });
        });

        $d.off('click', '.user_edit_close').on('click', '.user_edit_close', function(){

            $('#app_loader').fadeIn(200);
            require('./modLoad').TableUsers();
            return;
        });
    },

    AjaxUpdate : ( data ) => {

        $.ajax({
            url: '/app/update/user',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

            $('#app_loader').fadeOut(200);

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });
    }
}
module.exports = modUserUpdate;