let modSearch = {

    SearchJs : () => {

        jQuery.expr[':'].icontains = function(a, i, m) {

            var rExps=[
                {re: /[\xC0-\xC6]/g, ch: "A"},
                {re: /[\xE0-\xE6]/g, ch: "a"},
                {re: /[\xC8-\xCB]/g, ch: "E"},
                {re: /[\xE8-\xEB]/g, ch: "e"},
                {re: /[\xCC-\xCF]/g, ch: "I"},
                {re: /[\xEC-\xEF]/g, ch: "i"},
                {re: /[\xD2-\xD6]/g, ch: "O"},
                {re: /[\xF2-\xF6]/g, ch: "o"},
                {re: /[\xD9-\xDC]/g, ch: "U"},
                {re: /[\xF9-\xFC]/g, ch: "u"},
                {re: /[\xC7-\xE7]/g, ch: "c"},
                {re: /[\xD1]/g, ch: "N"},
                {re: /[\xF1]/g, ch: "n"}
            ];
        
            var element = $(a).text(), search = m[3];
        
            $.each(rExps, function() {
                element = element.replace(this.re, this.ch);
                search = search.replace(this.re, this.ch);
            });

            return element.toUpperCase().indexOf(search.toUpperCase()) >= 0;
        };

        $d.off('keyup', 'input[name="search"]').on('keyup', 'input[name="search"]', function(){

            var value = $(this).val();
        
            if( value.length >= 2 ){

                if( $('.table_app').length ) {

                    $('tbody > tr:visible').addClass('is_visible').hide();
                    
                    $('tbody > tr:icontains("'+value+'")').show();

                    if( $('.table_pagination_page').length ) $('.table_pagination_page').hide();
                }

                if( $('.app_dashboard').length ) {

                    $('.dashboard_flex_item').addClass('is_visible').hide();
                    
                    $('.dashboard_flex_item:icontains("'+value+'")').show();
                }
            }
            else{

                if( $('.table_app').length ) {

                    $('tbody > tr.is_visible').show().removeClass('is_visible');

                    if( $('.table_pagination_page').length )  {

                        $('.table_app_pag').find('tbody > tr').hide();
                        $('.table_pagination_page').show();
                        $('.table_pagination_item_selected').trigger('click');
                    }
                }

                if( $('.app_dashboard').length ) {

                    $('.dashboard_flex_item.is_visible').show().removeClass('is_visible');
                }
            }
        });
    },

}
module.exports = modSearch;