let modUserParams = {

    curentTheme : $('body').attr('class'),

    Init : () => {
        modUserParams.Actions();
    },

    Actions : () => {

        $d.off('click', '.theme_item_color').on('click', '.theme_item_color', function(){

            let theme = $(this).parent('.user_params_theme_item').attr('data-theme');

            $('.theme_item_color').removeClass('theme_item_color_selected');
            $(this).addClass('theme_item_color_selected');

            $('body').attr('class', theme);

            return;
        });

        $d.off('mouseenter', '.theme_item_color').on('mouseenter', '.theme_item_color', function(){

            let theme = $(this).parent('.user_params_theme_item').attr('data-theme');

            $('body').attr('class', theme);

            return;
        });

        $d.off('mouseleave', '.theme_item_color').on('mouseleave', '.theme_item_color', function(){

            //$('body').attr('class', modUserParams.curentTheme);
            let selectedTheme = $('.theme_item_color_selected').parent('.user_params_theme_item').attr('data-theme');

            $('body').attr('class', selectedTheme);

            return;
        });

        $d.off('submit', '#user_params_update_form').on('submit', '#user_params_update_form', function( e ){

            e.preventDefault();

            let data = { 
                user_id : filterXSS($('input[name="params_user_id"]').val()),
                user_email : filterXSS($('input[name="params_user_email"]').val()),
                user_theme : filterXSS($('.theme_item_color_selected').parent('.user_params_theme_item').attr('data-theme')),
                user_pagination_clients : filterXSS($('input[name="params_user_pagination_clients"]').val()),
                user_pagination_users : filterXSS($('input[name="params_user_pagination_users"]').val())
            }

            modUserParams.AjaxUpdate( data );
        });

        $d.off('click', '.user_params_close').on('click', '.user_params_close', function(){

            $('#app_loader').fadeIn(200);
            require('./modLoad').TableUsers();
            return;
        });
    },

    AjaxUpdate : ( data ) => {

        $.ajax({
            url: '/app/params/user/update',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                if( $('.user_params_container').length ) {

                    //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                    $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                }
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

            $('#app_loader').fadeOut(200);

            require('./modCommon').Storage();

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });
    }
}
module.exports = modUserParams;