let modCommon = {

    Init : () => {
        modCommon.Modal();
        modCommon.Actions();
        modCommon.Storage();
        modCommon.OnScroll();
    },

    ArrayLast : (array, n) => {

        if (array == null) return void 0;
        if (n == null) return array[array.length - 1];

        return array.slice(Math.max(array.length - n, 0));  
    },

    OnScroll : () => {

        $(window).on('scroll', function( e ){

            if( window.pageYOffset > 50 ) {

                $('.optimized_app_notice').fadeOut( 200 );
            }
            
            if( window.pageYOffset < 49 ) {

                $('.optimized_app_notice').fadeIn( 200 );
            }

            if( window.pageYOffset > 550 ) {

                $('.scroll_to_top').fadeIn( 300 );
            }

            if( window.pageYOffset < 549 ) {

                $('.scroll_to_top').fadeOut( 300 );
            }
        });
    },

    Actions : () => {

        $d.off('click', '.scroll_to_top').on('click', '.scroll_to_top', function(){

            $("html, body").animate({ scrollTop: 0 }, "slow");
            return;
        });

        $d.off('click', '.app_logout').on('click', '.app_logout', function(){

            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                window.location.href = window.location.origin + '/logout';
                return;
            });
        });

        $d.off('click', '.clients_list,.app_clients_list').on('click', '.clients_list,.app_clients_list', function(){

            let params = false;

            if( $(this).parent().attr('data-query') ) {

                params = $(this).parent().attr('data-query');
                require('./modLoad').TableClientsFiltered( params );
            }
            else {
                require('./modLoad').TableClients();
            }

            $('#app_loader').fadeIn(200);
            
            return;
        });

        $d.off('click', '.users_manager,.app_users_list').on('click', '.users_manager,.app_users_list', function(){

            let params = false;

            if( $(this).parent().attr('data-query') ) {

                params = $(this).parent().attr('data-query');
                require('./modLoad').TableUsersFiltered( params );
            }
            else {
                require('./modLoad').TableUsers();
            }

            $('#app_loader').fadeIn(200);
            
            return;
        });

        $d.off('click', '.app_name').on('click', '.app_name', function(){

            $('#app_loader').fadeIn(200);
            require('./modLoad').Dashboard();
            return;
        });

        $d.off('click', '.form_notice_close').on('click', '.form_notice_close', function(){

            $(this).parent('.form_notice').hide().removeClass('form_notice_visible');
            return;
        });

        $d.off('click', '.view_content_close').on('click', '.view_content_close', function(){

            $('#app_loader').fadeIn(200);
            require('./modLoad').Dashboard();
            return;
        });

        $d.off('click', '.users_params,.app_user_params').on('click', '.users_params,.app_user_params', function(){
            $('#app_loader').fadeIn(200);
            require('./modLoad').UserParams();
            return;
        });
    },

    Storage : () => {

        let theme = $('body').attr('class');

        window.localStorage.setItem( 'VDASH_theme', theme );

        return;
    },

    Modal : () => {

        $d.off('click', '.modal_btn_cancel').on('click', '.modal_btn_cancel', function(){

            $('.modal').fadeOut( 200 );
            return;
        });
    },

    FormatOctets : (bytes, decimals = 2) => {
        
        if (bytes === 0) return '0 Bytes';
    
        const k = 1000;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}
module.exports = modCommon;