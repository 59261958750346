import "../scss/app.scss";

"use-strict";

window.jQuery   = require('jquery');
window.$        = jQuery;
window.$d       = $(document);
window.xss      = require('xss');

$d.ready( function(){

    if( $('#login').length ) {

        require('./modules/modLogin').Init();
        return;
    }

    require('./modules/modCommon').Init();
    require('./modules/modSearch').SearchJs();
    require('./modules/modLoad').Init();
    require('./modules/modClient').Init();
    require('./modules/modClientUpdate').Init();
    require('./modules/modUsers').Init();
    require('./modules/modUserUpdate').Init();
    require('./modules/modUserParams').Init();
});