let modUsers = {

    Init : () => {
        modUsers.Action();
    },

    Action : () => {

        $d.off('click', '.option_add_user').on('click', '.option_add_user', function(){

            if( $('.form_add_user').is(':visible') ) {
                $('.form_add_user').slideUp( 200 );
                $(this).removeClass('option_form_add_open');
            }
            else {
                $('.form_add_user').slideDown( 200 );
                $(this).addClass('option_form_add_open');
            }
        });

        $d.off('submit', '#form_add_user').on('submit', '#form_add_user', function( e ){

            e.preventDefault();

            $('#app_loader').show();

            if( $('input[name="user_password"]').val() != $('input[name="user_password_confirm"]').val()) {

                $('#app_loader').fadeOut( 200 );

                //$('.form_notice_error').text( 'Les mots de passe ne correspondent pas !' ).fadeIn( 200 );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( 'Les mots de passe ne correspondent pas !' );

                return;
            }

            let data = {
                user_email : filterXSS($('input[name="user_email"]').val()),
                user_password : filterXSS($('input[name="user_password"]').val()),
                user_password_confirm : filterXSS($('input[name="user_password_confirm"]').val()),
                user_role : filterXSS($('select[name="user_role"]').val()),
            }

            modUsers.AjaxAdd( data );
        });


        $d.off('click', '.user_edit').on('click', '.user_edit', function(){

            let userId = $(this).parent().parent('.client_row_data').attr('data-id');
            $('#app_loader').fadeIn(200);
            require('./modLoad').UserEdit( userId );
        });

        $d.off('click', '.user_remove').on('click', '.user_remove', function(){

            let userId = $(this).parent().parent('.client_row_data').attr('data-id');
            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                if( $('.form_add_user').length ) {

                    $('.modal').hide();
                    $('#app_loader').show();

                    let data = {
                        user_id : filterXSS(userId)
                    }
                    modUsers.AjaxDelete( data );
                }
            });
        });
    },

    AjaxAdd : ( data ) => {

        $.ajax({
            url: '/app/add/user',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                $('.form_add').slideUp( 200 );

                //require('./modLoad').TableUsers();
                require('./modLoad').GetHashUrl();
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

            $('#app_loader').fadeOut(200);

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });
    },

    AjaxDelete : ( data ) => {

        $.ajax({
            url: '/app/delete/user',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                $('.form_add').slideUp( 200 );

                //require('./modLoad').TableUsers();
                require('./modLoad').GetHashUrl();
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });

    }
}
module.exports = modUsers;