let modSync = {

    Init : () => {
        //modLoop.LoopClientOnLoad();
    },

    LoopAllClient : () => {

        $('tr.client_row_data').each( function( index, item ){

            let id      = $(item).attr('data-id'),
                url     = $(item).find('td.client_data_url').text(), 
                type    = $(item).find('td.client_data_type').text(), 
                key     = $(item).find('td.client_data_key').text();

            if( type === 'wordpress' ) {
                modSync.GetApi( id, url + '/wp-json/wp-vernalis/v1/get/' + key );
            }
        });
    },

    GetApi : ( id, link, single=false ) => {

        $('tr.client_row_data[data-id="'+id+'"]').find('.client_sync').addClass('client_sync_anim');

        // WORDPPRESS
        //'https://wp.docker/wp-json/wp-vernalis/v1/get/39pgL1QKqlQalxCEG2I2z2uwXr8yrGmJ'

        $.ajax({
            url: link,
            method: 'GET',
            dataType: 'json'
        }).done( function( datas ){

            if( datas && datas.response === "success" ) {

                let dataClient = null;

                let contrat = datas.contrat.volume, stringContrat = require('./modCommon').FormatOctets( contrat, 2 );

                $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_state').html('<div class="mini_ball mini_ball_green"></div>');
                $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_contrat').attr('data-octet', contrat ).text( stringContrat );
                $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_created_at').text(datas.contrat.created_at);

                if( datas.client_data ) {

                    dataClient = datas.client_data;
                    dataClient = Object.values(dataClient);
                    dataClient = dataClient.pop();

                    let client_volume       = parseFloat( dataClient.volume_state );
                    let stringClientVolume  = require('./modCommon').FormatOctets( client_volume, 2 );
                    let volume_diff         = contrat - client_volume;
                    let stringVolumeDiff    = '';

                    let isNegative = false;

                        if( volume_diff < 0 ) {

                            volume_diff = volume_diff*-1;
                            stringVolumeDiff = '- '+require('./modCommon').FormatOctets( volume_diff, 2 );
                            isNegative = true;
                        }
                        else {
                            stringVolumeDiff = require('./modCommon').FormatOctets( volume_diff, 2 );
                        }

                    $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_volume_client').attr('data-octet', client_volume ).text( stringClientVolume );
                    $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_volume_diff').attr('data-octet', volume_diff ).text( stringVolumeDiff );

                    if( isNegative ) $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_volume_diff').addClass('volume_diff_negative');
                    else $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_volume_diff').removeClass('volume_diff_negative');
                }

                datas.id_client = id;

                if( single ) {

                    let html = 
                        `<div>Synchronisation réussie !</div>
                        <div>Données client du ${dataClient.created_at}</div>`;

                    //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').html( html );
                    $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( html );
                }

                modSync.AjaxAddSync( datas );
            }

            $('tr.client_row_data[data-id="'+id+'"]').find('.client_sync').removeClass('client_sync_anim');
    
        }).fail( function( jqXHR, textStatus  ){

            if( jqXHR ) {
                $('tr.client_row_data[data-id="'+id+'"]').find('td.client_data_state').html('<div class="mini_ball mini_ball_red"></div>');
            }

            $('tr.client_row_data[data-id="'+id+'"]').find('.client_sync').removeClass('client_sync_anim');

            if( textStatus === 'error' ) {

                if( single ) {

                    let html =
                    `<div>
                        <div>1 - Verifiez la clé API..</div>
                        <div>2 - Verifiez si l'API client est active..</div>
                        <div>3 - Verifiez l'url client..</div>
                        <div>4 - Si le site du client est en http</div> 
                        <div>il y aura une erreur "Blocage du chargement du contenu mixte actif"</div>
                        <div>Pour fixer le problème : <a href="https://support.mozilla.org/fr/kb/blocage-du-contenu-mixte-avec-firefox#w_daebloquer-le-contenu-mixte" target="_blank">blocage du contenu mixte avec firefox</a></div>
                    </div>`;

                    //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').html( html );
                    $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( html );
                }
            }
        });
    },

    AjaxAddSync : ( datas ) => {

        $.ajax({
            url: '/app/sync/client',
            data: JSON.stringify( datas ),
            method: 'POST',
            dataType: 'JSON'
        }).done(function( result ){

            console.log( result );

        }).fail( function( err ){

            console.log( err );
        });
    }
}
module.exports = modSync;