let modClientUpdate = {

    Init : () => {
        modClientUpdate.Action();
    },
    Action : () => {

        $d.off('submit', '#client_edit_update_form').on('submit', '#client_edit_update_form', function( e ){

            e.preventDefault();

            let clientId = $('input[name="edit_client_id"]').val();
            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                if( $('#client_edit_update_form').length ) {

                    $('.modal').hide();
                    $('#app_loader').show();

                    let data = {
                        client_id : filterXSS(clientId),
                        client_name : filterXSS($('input[name="edit_client_name"]').val()),
                        client_url : filterXSS($('input[name="edit_client_url"]').val()),
                        client_token_key : filterXSS($('input[name="edit_client_token_key"]').val()),
                        client_type_site : filterXSS($('select[name="edit_client_type_site"]').val()),
                        client_contrat_start : filterXSS($('input[name="edit_client_start_at"]').val()),
                        client_contrat_month_length : filterXSS($('input[name="edit_client_month_length"]').val())
                    }

                    modClientUpdate.AjaxUpdate( data );
                }
            });
        });

        $d.off('click', '.client_edit_close').on('click', '.client_edit_close', function(){

            $('#app_loader').fadeIn(200);
            require('./modLoad').TableClients();
            return;
        });

        $d.off('submit', '#client_edit_comment_form').on('submit', '#client_edit_comment_form', function( e ){

            e.preventDefault();

            let clientId = $('input[name="edit_client_id"]').val();
            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                if( $('#client_edit_comment_form').length ) {

                    $('.modal').hide();
                    $('#app_loader').show();

                    let data = {
                        client_id : filterXSS(clientId),
                        client_comment : filterXSS($('textarea[name="edit_client_comment"]').val())
                    }

                    modClientUpdate.AjaxAddComment( data );
                }
            });
        });
    },

    HistoryActions : () => {

        if( $('.client_history_years_content').length && $('.client_history_datas_items').length ) {

            let firstYear = $('.client_history_years_item').first().attr('data-year');

            $('.client_history_years_item').first().addClass('client_history_years_item_selected');

            if( $('.client_history_datas_item[data-year="'+firstYear+'"]').length ) {
                $('.client_history_datas_item[data-year="'+firstYear+'"]').show();
            }

            $('.client_history_datas_item').find('.history_data_volume').each( function( i, e ){

                let volume = require('./modCommon').FormatOctets( $(e).text(), 2 );

                $(e).text( volume );
            });

            $('.client_history_datas_item').find('.history_data_volume_state').each( function( i, e ){

                let volume_state = require('./modCommon').FormatOctets( $(e).text(), 2 );

                $(e).text( volume_state );
            });
        }

        $d.off('click', '.client_history_years_item').on('click', '.client_history_years_item', function(){

            $('.client_history_years_item').removeClass('client_history_years_item_selected');

            $(this).addClass('client_history_years_item_selected');

            $('.client_history_datas_item').hide();

            let year = $(this).attr('data-year');

            $('.client_history_datas_item[data-year="'+year+'"]').show();
        });
    },

    AjaxUpdate : ( data ) => {

        $.ajax({
            url: '/app/update/client',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

            $('#app_loader').fadeOut(200);

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });

    },

    AjaxAddComment : ( data ) => {

        $.ajax({
            url: '/app/client/add/comment',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

            require('./modLoad').GetHashUrl();

            //$('#app_loader').fadeOut(200);

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });

    }
}
module.exports = modClientUpdate;