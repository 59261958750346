let modLoad = {

    Init : () => {

        //modLoad.TableClients();
        //modLoad.Dashboard();
        modLoad.GetHashUrl();
    },

    GetHashUrl : () => {

        if( window.location.hash ) {

            let hash = window.location.hash;

            if( hash === '#/clients' ) {

                require('./modLoad').TableClients();
            }
            else if( hash === '#/users' ) {

                require('./modLoad').TableUsers();
            }
            else if( hash === '#/user/params' ) {

                require('./modLoad').UserParams();
            }
            else if( hash.indexOf('/clients/edit/') != -1 ) {

                var last = hash.split('/').pop();
                require('./modLoad').ClientEdit( last );
            }
            else if( hash.indexOf('/clients?order_by') != -1 ) {

                var params = hash.split('?').pop();
                require('./modLoad').TableClientsFiltered( params );
            }
            else if( hash.indexOf('/users/edit/') != -1 ) {

                var last = hash.split('/').pop();
                require('./modLoad').UserEdit( last );
            }
            else if( hash.indexOf('/users?order_by') != -1 ) {

                var params = hash.split('?').pop();
                require('./modLoad').TableUsersFiltered( params );
            }
            else {
                require('./modLoad').Dashboard();
            }
        } 
        else {
            require('./modLoad').Dashboard();
        }
    },

    Dashboard : () => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/dashboard',
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    $('.dashboard_flex_item').each( function( i, e ){

                        $(e).delay(100*i).slideDown(300);
                    });

                    history.pushState("", document.title, window.location.pathname + window.location.search);
                }
            }).fail(function( err ){
                console.log( err );
            });
        }
    },

    ClientEdit : ( clientId ) => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/clients/edit/'+clientId,
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    require('./modClientUpdate').HistoryActions();

                    window.location.hash='/clients/edit/'+clientId;
                }
            }).fail(function( err ){
                console.log( err );
            });
        }
    },

    TableClientsFiltered : ( params ) => {

        if( ('#main').length && params ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/clients?'+params,
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    require('./modTables').Paginate();

                    window.location.hash='/clients?'+params;
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
            
        }
    },

    TableClients : () => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/clients',
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    require('./modTables').Paginate();

                    window.location.hash='/clients';
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
        }
    },

    UserParams : () => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : '/app/user/params',
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    window.location.hash='/user/params';
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
        }
    },

    UserEdit : ( userId ) => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/users/edit/'+userId,
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    window.location.hash='/users/edit/'+userId;
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
        }
    },

    TableUsersFiltered : ( params ) => {

        if( ('#main').length && params ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/users?'+params,
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    require('./modTables').Paginate();

                    window.location.hash='/users?'+params;
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
            
        }
    },

    TableUsers : () => {

        if( $('#main').length ) {

            $('#main').find('.table_load').html('');
            $('#main').find('.table_container').show();

            $.ajax({
                url : 'app/users',
                method: 'GET',
                dataType : 'HTML'
            }).done( function( data ){

                if( data ) {
                    $('#main').find('.table_load').html( data );
                    $('#app_loader').fadeOut(200);

                    require('./modTables').Paginate();

                    window.location.hash='/users';
                }
                else {
                    $('#app_loader').fadeOut(200);
                }
            }).fail(function( err ){
                console.log( err );
                $('#app_loader').fadeOut(200);
            })
        }
    }
}
module.exports = modLoad;