let modTables = {

    numberItems : 5,  
    PaginationChanged : false,

    Init : () => {

        modTables.Sort();
        modTables.OptionNotice();

        if( $('.table_app_pag').length ) {

            $('.table_app_pag').find('tbody > tr').hide();
            if( $('.table_pagination_page').length ) $('.table_pagination_page').remove();

            modTables.numberItems = $('input[name="table_pagination_items_number"]').val() != '' ? parseInt( $('input[name="table_pagination_items_number"]').val() ) : 5;

            $('.table_app_pag').find('tbody > tr').each( function( i, e ){

                if( i + 1 <= modTables.numberItems ) $(e).show();
            });

            $('#table_load').append('<div class="table_pagination_page"></div>');

            let totalItems = parseInt( $('.table_app_pag').find('tbody > tr').length );
            let totalPages = totalItems / modTables.numberItems;

            for( var i= 0; i < totalPages; i++ ) {

                let selected = (i+1) === 1 ? 'table_pagination_item_selected' : '', isHidden = (i+1) > 10 ? 'table_pagination_item_hidden' : '';

                $('.table_pagination_page').append('<div data-page="'+(i+1)+'" class="table_pagination_item '+selected+' '+isHidden+'">'+(i+1)+'</div>');
            }

            if( totalPages > 10 ) $('.table_pagination_page').append('<div class="table_pagination_item_next"><i class="fas fa-angle-right"></i></div>');   
        }
    },

    OptionNotice : () => {

        if( $('.table_app').length ) {

            $d.off('mouseenter', '.option_item, .table_item_action').on('mouseenter', '.option_item, .table_item_action', function(){

                let notice = $(this).attr('data-notice');

                if( notice && notice != '' ) {

                    $('.table_options').find('.option_notice').text( notice ).show();
                }
            });

            $d.off('mouseleave', '.option_item, .table_item_action').on('mouseleave', '.option_item, .table_item_action', function(){

                $('.table_options').find('.option_notice').text( '' ).hide();
            });
        }
    },

    Paginate : () => {

        modTables.Init();

        $d.off('click', '.table_pagination_item_next').on('click', '.table_pagination_item_next', function(){

            let lastVisiblePage = parseInt( $('.table_pagination_item:visible').last().attr('data-page') );

            $('.table_pagination_item').hide();

            $('.table_pagination_item').each( function( i, e ){

                if( ( i + 1 ) >= lastVisiblePage && (i + 1) < ( lastVisiblePage+10 )) $(e).show();
            });

            if( !$('.table_pagination_item_prev').length ) {
                $('.table_pagination_page').prepend('<div class="table_pagination_item_prev"><i class="fas fa-angle-left"></i></div>');
            }

            let totalPages = $('.table_pagination_item').length;

            if( $('.table_pagination_item[data-page="'+totalPages+'"]').is(':visible') ) $('.table_pagination_item_next').remove();
        });

        $d.off('click', '.table_pagination_item_prev').on('click', '.table_pagination_item_prev', function(){

            let firstVisiblePage = parseInt( $('.table_pagination_item:visible').first().attr('data-page') );

            $('.table_pagination_item').hide();

            $('.table_pagination_item').each( function( i, e ){

                if( ( i + 1 ) <= firstVisiblePage && (i + 1) > ( firstVisiblePage - 10 )) $(e).show();
            });

            if( $('.table_pagination_item[data-page="1"]').is(':visible') ) $('.table_pagination_item_prev').remove();

            let totalPages = $('.table_pagination_item').length;

            if( totalPages > 10 && !$('.table_pagination_item_next') .length ) {
                $('.table_pagination_page').append('<div class="table_pagination_item_next"><i class="fas fa-angle-right"></i></div>');   
            }
        });

        $d.off('change', 'input[name="table_pagination_items_number"]').on('change', 'input[name="table_pagination_items_number"]', function(){

            modTables.PaginationChanged = true;

            modTables.Paginate();

            return;
        });

        $d.off('mouseleave', '.option_pagination').on('mouseleave', '.option_pagination', function(){

            if( modTables.PaginationChanged  ) {

                let tableView   = $(this).attr('data-view'), 
                user_id         = $(this).attr('user-id'),
                value           = $(this).find('input[name="table_pagination_items_number"]').val();

                let data = { 
                    user_id : user_id
                }

                if( tableView === 'clients' ) data.user_pagination_clients = value
                if( tableView === 'users' ) data.user_pagination_users = value

                require('./modUserParams').AjaxUpdate( data );

                modTables.PaginationChanged = false;
                
                return;
            }
        });

        $d.off('click', '.table_pagination_items_number_up').on('click', '.table_pagination_items_number_up', function(){

            let totalItems = parseInt( $('.table_app_pag').find('tbody > tr').length );
            let $parent     = $(this).parent();
            let value       = parseInt( $parent.find('input[name="table_pagination_items_number"]').val() );

            if( value < totalItems ) {

                let newValue    = value + 1;
                $parent.find('input[name="table_pagination_items_number"]').val( newValue ).trigger('change');
            }
        });

        $d.off('click', '.table_pagination_items_number_down').on('click', '.table_pagination_items_number_down', function(){

            let minItems    = 1;
            let $parent     = $(this).parent();
            let value       = parseInt( $parent.find('input[name="table_pagination_items_number"]').val() );

            if( value > minItems ) {

                let newValue    = value - 1;
                $parent.find('input[name="table_pagination_items_number"]').val( newValue ).trigger('change');
            }
        });

        $d.off('click', '.table_view_all_items').on('click', '.table_view_all_items', function(){

            if( $('.table_pagination_page').is(':visible') ) {

                $('.table_pagination_page').hide();
                $('.table_app_pag').find('tbody > tr').show();
            }
            else {

                $('.table_app_pag').find('tbody > tr').hide();
                $('.table_pagination_page').show();
                $('.table_pagination_item_selected').trigger('click');
            }
        });

        $d.off('click', '.table_pagination_item').on('click', '.table_pagination_item', function(){

            $('.table_pagination_item').removeClass('table_pagination_item_selected');
            $(this).addClass('table_pagination_item_selected');

            let page = $(this).attr('data-page');

            let numberItems = modTables.numberItems * parseInt( page );
            let isHiden     = numberItems - modTables.numberItems;

            $('.table_app_pag').find('tbody > tr').hide();

            $('.table_app_pag').find('tbody > tr').each( function( i, e ){

                if( (i + 1) > isHiden &&  (i + 1) <= numberItems ) $(e).show();
            });
        });
    },

    Sort : () => {

        if( $('.table_app').length ) {

            let currentOrderBy      = $('input[name="table_order_by"]').val(),
                currentOrderSort    = $('input[name="table_order_sort"]').val()

            if( $('.table_sort[sort-by="'+currentOrderBy+'"]').length ) {

                $('.table_sort[sort-by="'+currentOrderBy+'"]').attr( 'sort-type', currentOrderSort === 'ASC' ? 'DESC' : 'ASC' );

                if( currentOrderSort === 'ASC' ) {
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_default').hide();
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_up').hide();
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_down').show();
                }

                if( currentOrderSort === 'DESC' ) {
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_default').hide();
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_down').hide();
                    $('.table_sort[sort-by="'+currentOrderBy+'"]').find('.table_sort_up').show();   
                }
            }


            $d.off('click', '.table_sort').on('click', '.table_sort', function(){

                $('#app_loader').show();
    
                let orderBy     = $(this).attr('sort-by'), 
                    sortType    = $(this).attr('sort-type'),
                    params      = 'order_by='+orderBy+'&order_sort='+sortType;
    
                if( $('#table_clients_list').length ) require('./modLoad').TableClientsFiltered( params );
                else if( $('#table_users_list').length ) require('./modLoad').TableUsersFiltered( params );
            });

        }
    }
}
module.exports = modTables;