let modClient = {

    Init : () => {

        modClient.Action();
    },

    Action : () => {

        $d.off('click', '.option_sync_allclient').on('click', '.option_sync_allclient', function(){
            //$('#app_loader').show();
            require('./modSync').LoopAllClient();
        });

        $d.off('click', '.client_edit').on('click', '.client_edit', function(){

            let clientId = $(this).parent().parent('.client_row_data').attr('data-id');
            $('#app_loader').fadeIn(200);
            require('./modLoad').ClientEdit( clientId );
        });

        $d.off('click', '.client_sync').on('click', '.client_sync', function(){

            let id = $(this).parent().parent('.client_row_data').attr('data-id');

            if( id ) {

                // @Contenu mixte error : https://support.mozilla.org/fr/kb/blocage-du-contenu-mixte-avec-firefox

                let url     = $(this).parent().parent('.client_row_data').find('td.client_data_url').text(),
                    type    = $(this).parent().parent('.client_row_data').find('td.client_data_type').text(), 
                    key     = $(this).parent().parent('.client_row_data').find('td.client_data_key').text();

                if( type === 'wordpress' ) {
                    require('./modSync').GetApi( id, url + '/wp-json/wp-vernalis/v1/get/' + key, true );
                }
            }
        });

        $d.off('click', '.client_remove').on('click', '.client_remove', function(){

            let clientId = $(this).parent().parent('.client_row_data').attr('data-id');
            $('.modal_confirm').fadeIn( 200 );

            $d.off('click', '.modal_btn_confirm').on('click', '.modal_btn_confirm', function(){

                if( $('.form_add_client').length ) {

                    $('.modal').hide();
                    $('#app_loader').show();

                    let data = {
                        client_id : filterXSS( clientId )
                    }
                    modClient.AjaxDelete( data );
                }
            });
        });

        $d.off('click', '.option_add_client').on('click', '.option_add_client', function(){

            if( $('.form_add_client').is(':visible') ) {
                $('.form_add_client').slideUp( 200 );
                $(this).removeClass('option_form_add_open');
            }
            else {
                $('.form_add_client').slideDown( 200 );
                $(this).addClass('option_form_add_open');
            }
        });

        $d.off('submit', '#form_add_client').on('submit', '#form_add_client', function( e ){

            e.preventDefault();

            $('#app_loader').show();

            let data = {
                client_name : filterXSS($('input[name="client_name"]').val()),
                client_url : filterXSS($('input[name="client_url"]').val()),
                client_token_key : filterXSS($('input[name="client_token_key"]').val()),
                client_type_site : filterXSS($('select[name="client_type_site"]').val()),
                client_contrat_start : filterXSS($('input[name="client_contrat_start"]').val()),
                client_contrat_month_length : filterXSS($('input[name="client_contrat_month_length"]').val())
            }

            modClient.AjaxAdd( data );
        });
    },

    AjaxAdd : ( data ) => {

        $.ajax({
            url: '/app/add/client',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                
                $('.form_add').slideUp( 200 );

                //require('./modLoad').TableClients();
                require('./modLoad').GetHashUrl();
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
            }

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });
    },

    AjaxDelete : ( data ) => {

        $.ajax({
            url: '/app/delete/client',
            dataType: 'JSON',
            method: 'POST',
            data: JSON.stringify( data )
        }).done( function( result ){

            if( result && result.response === "success" ) {

                //$('.form_notice_success').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_success').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                $('.form_add').slideUp( 200 );

                //require('./modLoad').TableClients();
                require('./modLoad').GetHashUrl();
            }
            if( result && result.response === "error" ) {

                //$('.form_notice_error').fadeIn( 200 ).find('p.form_notice_text').text( result.message );
                $('.form_notice_error').show().addClass('form_notice_visible').find('p.form_notice_text').html( result.message );
                
            }

        }).fail( function( err ){

            console.log( err );

            $('#app_loader').fadeOut(200);
        });

    }
}
module.exports = modClient;